<template>
  <b-modal
      ref="add-miningpool-modal"
      size="lg"
      title="Add mining pool"
  >
    <b-form-group label="Name">
      <b-form-input
          v-model="form.name"
          placeholder="name"
          required
          :state="!$v.form.name.$dirty ? null :(!$v.form.name.$error)"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Expiration">
      <b-form-input
          v-model="form.expiration"
          placeholder="expiration"
          required
          :state="!$v.form.expiration.$dirty ? null :(!$v.form.expiration.$error)"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Status">
      <b-form-select
          required
          v-model="form.status"
          :options="options.status"
          :state="!$v.form.status.$dirty ? null :(!$v.form.status.$error)"
      ></b-form-select>
    </b-form-group>
    <b-form-group label="Activation time">
      <b-form-input
          v-model="form.activationTime"
          placeholder="activationTime"
          required
          :state="!$v.form.activationTime.$dirty ? null :(!$v.form.activationTime.$error) && form.activationTime >= 0"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Price">
      <b-form-input
          v-model="form.price"
          placeholder="price"
          required
          :state="!$v.form.price.$dirty ? null :(!$v.form.price.$error) && form.price >= 0"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Commissions percent">
      <b-form-input
          v-model="form.commissionsPercent"
          placeholder="commissionsPercent"
          required
          :state="!$v.form.commissionsPercent.$dirty ? null :(!$v.form.commissionsPercent.$error) && form.commissionsPercent >= 0"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Commissions level">
      <b-form-input
          v-model="form.commissionsLevel"
          placeholder="commissionsLevel"
          required
          :state="!$v.form.commissionsLevel.$dirty ? null :(!$v.form.commissionsLevel.$error) && form.commissionsLevel >= 0"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="level">
      <b-form-input
          v-model="form.level"
          placeholder="level"
          required
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Starter pack">
      <b-form-select
          required
          v-model="form.isStarterPack"
          :options="options.isStarterPack"
          :state="!$v.form.isStarterPack.$dirty ? null :(!$v.form.isStarterPack.$error)"
      ></b-form-select>
    </b-form-group>
    <template #modal-footer>
      <div class="w-100">
        <div class="buttons float-right">
          <b-button type="button" variant="secondary" class="mr-1" @click="hideModal()">{{ $t('btn.cancel') }}
          </b-button>
          <b-button variant="primary" :disabled="busy" @click="submit()">
            <div v-if="busy">
              <b-spinner small type="grow"></b-spinner>
              {{ $t('btn.state.saving') }}
            </div>
            <div v-else>
              {{ $t('btn.save') }}
            </div>
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>
<script>

import {decimal, required} from "vuelidate/lib/validators";
import alertify from "alertifyjs";
import axios from "axios";

export default {
  data() {
    return {
      id: null,
      busy: false,
      title: null,
      groups: [],
      serverError: null,
      form: {
        id: null,
        name: null,
        expiration: null,
        level: null,
        price: null,
        activationTime: null,
        isStarterPack: null,
        status: null,
        commissionsPercent: null,
        commissionsLevel: null,
      },
      options: {
        status: [
          'active',
          'draft'
        ],
        isStarterPack: [
          { value: true, text: 'Yes' },
          { value: false, text: 'No' },
        ]

      }
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      expiration: {
        required,
        decimal
      },
      status: {
        required
      },
      price: {
        required
      },
      activationTime: {
        required
      },
      isStarterPack: {
        required
      },
      commissionsPercent: {
        required
      },
      commissionsLevel: {
        required
      },
    }
  },
  mounted() {
    this.openModal();
  },
  computed: {},
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let url = '';
        if(this.form.id === null){
          url = 'miningPoolStore/new';
        }else{
          url = 'miningPoolStore/update';
        }
        console.log(url);
        console.log(this.form);
        this.$store.dispatch(url, {...this.form})
            .then(() => {
              this.hideModal();
              setTimeout(() => {
                this.$emit('reload-items')
              }, 1000)
            })
            .catch((error) => {
              if (error.response.status === 400) {
                alertify.error(error.response.data.label);
              }

            })
      }
    },
    openModal() {
      this.$refs['add-miningpool-modal'].show();
    },
    openEditModal(id) {
      this.form.id = id;
      this.$refs['add-miningpool-modal'].show();
      axios.get(`/mining-pool/${id}/offers`)
          .then((response) => {
            this.form.name = response.data.name;
            this.form.expiration = response.data.expiration;
            this.form.activationTime = response.data.activationTime;
            this.form.isStarterPack = response.data.isStarterPack;
            this.form.status = response.data.status;
            this.form.price = response.data.price;
            this.form.level = response.data.level;
            this.form.commissionsPercent = response.data.commissionsPercent;
            this.form.commissionsLevel = response.data.commissionsLevel;
          })
          .catch((error) => {
            console.log(error);
          })

    },
    hideModal() {
      this.$refs['add-miningpool-modal'].hide();
    },
  }
}
</script>
